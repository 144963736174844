@import './colors';

.ant-typography.ant-typography-primary {
  color: $primary;
}

.ant-typography-primary {
  color: $primary !important;
}

.ant-btn-success {
  color: #fff;
  background-color: $success;
  border-color: $success;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff;
  background-color: #3ee279;
  border-color: #3ee279;
}

.ant-btn-warning {
  color: #fff;
  background-color: $warning;
  border-color: $warning;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #fff;
  background-color: #efa45e;
  border-color: #efa45e;
}

.ant-btn-success:disabled,
.ant-btn-success.ant-btn-disabled,
.ant-btn-warning:disabled,
.ant-btn-warning.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
