/*!
 *
 *  Project:  Isarel Pro LLM
 *  Author:   Misha Kav
 *
 */

@import './antd-styles';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800&display=swap');

html,
body {
  margin: 0;
}

body {
  position: relative;
}

@media (min-width: 768px) {
  body:before {
    content: '';
    position: absolute;
    top: 0;
    left: 60px;
    width: 30px;
    height: calc(100% - 70px);
    background: #f37c21;
  }
}
